.animation {
  @media (min-width: theme("screens.lg")) {
    max-width: 80vh;
  }

  &.faded {
    &::before,
    &::after {
      content: "";
      position: absolute;
      z-index: 10;
      bottom: 0;
      left: 0;
    }

    &::before {
      top: 20%;
      width: 15%;
      background: linear-gradient(90deg, rgba(255, 255, 255, 1) 46%, rgba(255, 255, 255, 0) 100%);
    }

    &::after {
      right: -1000px;
      height: 80%;
      bottom: -30%;
      background: linear-gradient(0deg, rgba(255, 255, 255, 1) 60%, rgba(255, 255, 255, 0) 100%);
    }
  }
}

.landing {
  main {
    font-family: "Circular Std", sans-serif;
    font-size: 1.6rem;
    scroll-behavior: smooth;
  }

  .page-size {
    max-width: 88rem;
    @apply mx-auto;
    &.padded {
      @apply p-4 lg:p-20 lg:pt-5 lg:pb-16;
    }
  }

  .hero-size {
    max-width: 88rem;
    @apply mx-auto;
    &.padded {
      @apply p-4 md:p-10 lg:py-20 lg:pt-5 lg:pb-4 lg:px-12;
    }
  }
}
